import {useTranslation} from "react-i18next";

const navigation = [
  {
    title: 'Solutions',
    items: [
      {title: 'Intranets', path: '/solutions/intranets'},
      {title: 'Customer platforms', path: '/solutions/customer-platforms'},
      {title: 'Partner portals', path: '/solutions/partner-portals'},
      {title: 'Integration platforms', path: '/solutions/integration-platforms'},
    ],
  },
  {
    title: 'Products',
    path: '/products',
  },
  {
    title: 'About Us',
    path: '/about-us',
  },
];

export default navigation;
