import React from 'react';
import styled from 'styled-components';

import FooterContact from '../components/Footer/Contact';
import FooterNavigation from '../components/Footer/Navigation';
import FooterCopyright from '../components/Footer/Copyright';

import FooterRow from '../components/Footer/Row';

const FooterMobile = () => (
  <Container>
    <FooterRow>
      <FooterNavigation/>
    </FooterRow>

    <FooterRow>
      <FooterContact/>
    </FooterRow>

    <FooterRow>
      <FooterCopyright>© 2O2O CoderyLabs Sp. z o.o. S.K. All Rights Reserved</FooterCopyright>
    </FooterRow>
  </Container>
);

const Container = styled.div`
  width: 100%;
  background: #222222;
  color: white;
  padding: 1.5rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default FooterMobile;
