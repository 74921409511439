import React from 'react';
import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, body, #gatsby-focus-wrapper, #___gatsby {
        width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
    }
    body {
        min-width: 320px;
        scroll-behavior: smooth;
        font-family: 'Dosis', sans-serif;
        font-size: 16px;

        a {
            text-decoration: none;
        }


        img {
            display: block !important;
        }

        textarea{
            font-family: 'Dosis', sans-serif;
        }
    }
`;

export default ({children}) => (
  <>
    <GlobalStyle/>
    {children}
  </>
);
